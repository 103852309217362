import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Home = () => import(/* webpackChunkName: "home" */ '../views/Home');
const Gallery = () =>
  import(/* webpackChunkName: "gallery" */ '../views/Gallery');
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/galeria',
    name: 'Galeria',
    component: Gallery,
  },
  {
    path: '/polityka-prywatnosci',
    name: 'Polityka Prywatności',
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
  },
});

export default router;
