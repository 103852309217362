<template>
  <main id="app">
    <cookie-banner v-if="!isPrivacyAgreed" />
    <section class="is-fixed">
      <navbar-top />
      <navbar-bottom />
    </section>
    <router-view />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    cookieBanner: () => import('./components/CookieBanner'),
    navbarTop: () => import('./components/NavbarTop'),
    navbarBottom: () => import('./components/NavbarBottom'),
  },
  computed: {
    ...mapGetters(['isPrivacyAgreed']),
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Techniker - zaopatrzenie techniczne';
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.is-fixed {
  position: fixed;
  z-index: 6;
  width: 100%;
}

html,
body {
  background: $white-bis !important;
  scroll-behavior: smooth;
}
</style>
