import Vue from 'vue';

// Importing ReCaptcha Vue plugin
import { VueReCaptcha } from 'vue-recaptcha-v3';

// Importing Vue Facebook Customer chat plugin for realtime facebook chat functionality
import VueFbCustomerChat from 'vue-fb-customer-chat';

import App from './App.vue';
import router from './router';
import store from './store';

// Importing main styles
import * as style from './styles/main.scss';

Vue.config.productionTip = false;

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});

Vue.use(VueFbCustomerChat, {
  page_id: process.env.VUE_APP_FACEBOOK_SITE_KEY,
  theme_color: '#f9cb24',
  locale: 'pl_PL',
});

new Vue({
  beforeCreate() {
    this.$store.commit('initialiseUserData');
  },
  style,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
