import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    agreedPrivacy: false,
  },
  mutations: {
    agreePrivacyPolicy(state, payload) {
      localStorage.setItem('agreedPrivacy', payload);
      state.agreedPrivacy = true;
    },

    initialiseUserData(state) {
      const agreedPrivacy = JSON.parse(localStorage.getItem('agreedPrivacy'));
      if (agreedPrivacy) state.agreedPrivacy = true;
    },
  },
  getters: {
    isPrivacyAgreed: state => !!state.agreedPrivacy,
  },
});
